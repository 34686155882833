import {send, trackingTypes} from './tracking_api';
import {makeContextFeature, makeItemFeature, makeListFeature, tracking} from "./shared";
import {track} from "@otto-ec/nav_star-track/src/js/star-track";

/**
 *
 */
function localTrack(features) {
    send(trackingTypes.moveAction,
        {},
        {
            name: 'click',
            features: features
        });
}

/**
 *
 *
 */
function parentList(el) {
    return el.closest(tracking.list) || el.closest('.nav_expanded-chips__list');
}

/**
 *
 *
 */
function closestChip(el) {
    let inner = el.querySelectorAll(tracking.item);
    if (inner.length === 1) {
        return inner[0];
    } else {
        return el.closest(tracking.item);
    }
}

/**
 *
 *
 */
export function trackModuleClick(eventTarget, contextElem) {
    let itemElem = closestChip(eventTarget);
    if (!!itemElem) {
        let contextFeature = makeContextFeature(contextElem, {withWkLabel: true}),
            /*                                               */
            listFeature = makeListFeature(itemElem, {idWithPos: false, withWkLabel: true});

        listFeature.status = 'clicked';

        localTrack([contextFeature, listFeature]);
    }
}

/**
 *
 *
 */
function isSelected(el) {
    return el.dataset.selected === 'true';
}

/**
 *
 *
 */
export function trackItemClick(eventTarget, contextElem) {
    let listElem = parentList(eventTarget);

    if (!!listElem) {
        let itemElem = closestChip(eventTarget);
        if (isSelected(itemElem)) {
            return;
        }

        let listPos = parseInt(listElem.dataset.position),
            contextFeature = makeContextFeature(contextElem, { withWkLabel: true }),
            listFeature = makeListFeature(listElem, {
                idWithPos: false,
                withWkLabel: true
            }),
            itemFeature = makeItemFeature(itemElem, listPos, {
                idWithPos: false,
                withWkLabel: true,
                status: 'clicked',
            });

        localTrack([contextFeature, listFeature, itemFeature]);
    }
}

/*                                                                                      */
function updateContextPosition(el, key, newPosition) {
    if (!el.dataset[key]) return el;
    let data = JSON.parse(el.dataset[key]);
    if (data && data[1] && data[1]["action"] && data[1]["action"]["features"] && data[1]["action"]["features"][0]) {
        data[1]["action"]["features"][0]["position"] = parseInt(newPosition);
    }
    el.dataset[key] = JSON.stringify(data);
    return el;
}

export function initLinkTracking(parentElem) {
    let listenerFunction;
    let container = parentElem.querySelectorAll(tracking.context);

    for (let i = 0; i < container.length; i++) {
        const contextElem = container[i];
        let breakpoints = [];
        try {
            breakpoints = JSON.parse(contextElem.dataset.breakpoints);
        } catch (e) {}

        if (contextElem.dataset.sliderOrigin === "kuvo") {
            contextElem.addEventListener('click', clickEvent => {
                if(clickEvent.target.closest('.nav_chip')) {
                    window.o_global.events().once('nav.kuvo.click', (evt, data) => {
                        track(clickEvent.target, 'user-action/click-kuvo-item', (commands) => {
                            if(commands[1]){
                                commands[1].eventMergeId = evt.eventMergeId;
                            }
                            return commands;
                        })
                    });
                    window.o_global.events().emit('tracking.bct.createEventContext', 'nav.kuvo.click');
                }
            });
        } else if (contextElem.dataset.sliderOrigin === "emptySuggest") {
            contextElem.addEventListener('click', clickEvent => {
                if (clickEvent.target.closest('.nav_chip')) {
                    track(clickEvent.target, 'user-action/click-empty-suggest-item');
                }
            });
        } else if (contextElem.dataset.sliderOrigin === "categorySuggestion-tiledesign") {
            contextElem.addEventListener('click', clickEvent => {
                const parentPositionOnStoreFront = parentElem.closest('[data-feature-order]')?.dataset.featureOrder;
                let tile = clickEvent.target.closest('.nav_tile');
                const key = 'navTrackingClickCategorySuggestionsTileItem';
                if (tile) {
                    track(
                        parentPositionOnStoreFront ? updateContextPosition(tile, key, parentPositionOnStoreFront) : tile,
                        'user-action/click-category-suggestions-tile-item'
                    );
                }
            });
        } else if (breakpoints.includes(o_global.breakpoint.getCurrentBreakpoint())) {
            listenerFunction = (contextElem.dataset.pageType === 'module' ? trackModuleClick : trackItemClick);
            contextElem.addEventListener('click', evt => {
                if (!evt.target.classList.contains("js_pl_expander--no-fade__toggle")) {
                    listenerFunction(evt.target, contextElem);
                }
            });
        }
    }
}
