import {trackPageView} from "./tracking/view_tracking";
import {makeContextFeatureId, makeListFeatureId, tracking} from "./tracking/shared";
import {VisibilityTracker} from "./tracking/visibility_tracker";
import {submitMiniActions} from "@otto-ec/nav_star-track/src/js/star-track";
import {initLinkTracking} from "./tracking/link_tracking";
import {logger} from "@otto-ec/global-resources/debug";

const log = logger("ft-nav.promirnaty.main");

const categorySuggestionsVisibilityTracker = (featureId) => new VisibilityTracker(
    () => submitMiniActions([{featureId: featureId, status: "visible", action: "scroll"}]));

function getVisitorId() {
    const cookie = document.cookie;

    if (!cookie) return null;

    const cookieValue = cookie.split("; ").find(row => row.startsWith("visitorId"));
    return cookieValue ? cookieValue.split("=")[1] : null;
}

export class CategorySuggestionsTiles extends HTMLElement {
    constructor() {
        super();
    }

    async personalise() {
        const customizertAbortController = new AbortController();
        const timeoutCustomizerAbort = setTimeout(() => customizertAbortController.abort(), 1000);
        const customizerResponse = await fetch('/nav-customizer/local-recos', {
            headers: {vid: getVisitorId()},
            signal: customizertAbortController.signal
        }).then(response => {
            clearTimeout(timeoutCustomizerAbort);
            return response;
        });
        if (customizerResponse.status === 200) {
            const promirnatyAbortController = new AbortController();
            const timeoutPromirnatyAbort = setTimeout(() => promirnatyAbortController.abort(), 1000);
            const personalisedContent = await fetch('/nav-promirnaty/category-suggestions?' + new URLSearchParams({
                id: this.id,
            }).toString(), {
                method: 'POST',
                body: await customizerResponse.text(),
                signal: promirnatyAbortController.signal
            }).then(response => {
                clearTimeout(timeoutPromirnatyAbort);
                return response;
            });
            if (personalisedContent.status === 200) this.innerHTML = await personalisedContent.text();
        }
    }

    connectedCallback() {
        log.debug("Initializing category suggestions tiles", {id: this.id});

        const initializeTilesTracking = () => {
            /*                                                                            */
            const tilesContainer = this.querySelector(`#nav_slider`);
            if (tilesContainer.closest('[data-feature-order]')) {
                tilesContainer.querySelector(tracking.context).dataset.position =
                    tilesContainer.closest('[data-feature-order]').dataset.featureOrder;
            }
            initLinkTracking(tilesContainer);
            trackPageView(tilesContainer, tilesContainer.dataset.sliderType, {});
            categorySuggestionsVisibilityTracker(makeContextFeatureId(this.id)).observe(tilesContainer);
            let tiles = Array.from(tilesContainer.querySelectorAll(".nav_tile"));
            tiles.map(tile => categorySuggestionsVisibilityTracker(makeListFeatureId(tile)).observe(tile));
        };
        /*                                                                                     */
        this.personalise().then(initializeTilesTracking, initializeTilesTracking);
    }
}
